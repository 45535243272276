<template>
  <section style="margin: 0 24px">
    <nav v-if="!$route.params.id" class="tabs-navbar" :class="{ dark: false }">
      <ul class="tabs-navbar-list">
        <template v-for="(item, index) in category_1">
          <li
            v-if="hasPermission(item.permission)"
            :key="index"
            class="tabs-navbar-list__item"
          >
            <router-link
              class="tabs-navbar-list__item-link"
              :to="`/report-category/${item.link}`"
            >
              {{ $t(item.name) }}
            </router-link>
          </li>
        </template>
      </ul>
    </nav>

    <router-view />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeName: "",
      category_1: [
        {
          name: "message.report_doctors",
          link: "report/report-doctors",
          permission: "doctors_report.index",
        },
        {
          name: "message.report_partner_doctors",
          link: "partnerDoctorReport/index",
          permission: "doctors_report.index",
        },
        {
          name: "message.report_partner_clinics",
          link: "partnerClinicReport/index",
          permission: "doctors_report.index",
        },
        {
          name: "message.report_insurance_company",
          link: "insuranceCompanyReport/index",
          permission: "doctors_report.index",
        },
        {
          name: "message.payment_reports",
          link: "paymentReport/index",
          permission: "doctors_report.index",
        },
        {
          name: "message.patientResidenceReport",
          link: "patientResidenceReport/index",
          permission: "doctors_report.index",
        },
        {
          name: "message.patientServiceReport",
          link: "patientServiceReport/index",
          permission: "doctors_report.index",
        },
        // {
        //   name: "message.dailyProcessReport",
        //   link: "dailyProcessReport/index",
        //   permission: "doctors_report.index",
        // },
        // {
        //   name: "message.doctorProcessReport",
        //   link: "doctorProcessReport/index",
        //   permission: "doctors_report.index",
        // },
        {
          name: "message.excelReport",
          link: "excelReports/index",
          permission: ["excelReports.1", "excelReports.2", "excelReports.3"],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      // mode: "MODE"
    }),
    // currentTabs() {
    //     if (this.$route.params.slug === "directory") {
    //         return this.directoryTabs;
    //     } else if (this.$route.params.slug === "finance") {
    //         return this.financeTabs;
    //     } else if (this.$route.params.slug === "production") {
    //         return this.productionTabs;
    //     }
    // }
  },

  mounted() {
    console.log("params", this.$route);
    this.activeName = this.$route.name;
  },
  methods: {
    handleClickTabs(tab) {
      // this.activeName = tab.name
      console.log(tab);
    },
  },
};
</script>

<style lang="scss">
.tabs-navbar {
  background-color: transparent;
  padding: 4px 0;
  border-bottom: 1px solid #ddd;
  margin: 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;

  // tabs-navbar-list
  &-list {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: auto;
    white-space: nowrap;

    // scrool bar style
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    // tabs-navbar-list__item
    &__item {
      position: relative;
      z-index: 1;
      transition: all 0.2s ease-in-out;

      &:hover a {
        color: #fff;
        background-color: #0c67c9;
      }

      // tabs-navbar-list__item-link
      &-link {
        display: block;
        color: #727b89;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none !important;
        border-radius: 5px;
        padding: 4px 10px;
        transition: all 0.2s ease-in-out;

        &.router-link-exact-active {
          color: #fff;
          background-color: #0c67c9;
        }
      }
    }
  }

  // tabs-navbar__buttons
  &__buttons {
    flex-shrink: 0;
  }

  // dark mode
  &.dark {
    border-color: #44587c75;

    .el-button {
      color: #fff;
      background-color: #44587c75;
      border-color: #44587c75;
    }

    // tabs-navbar-list
    .tabs-navbar-list__item {
      color: #fff;
    }
  }
}
</style>
